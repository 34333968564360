import { matchingSelector } from "@devowl-wp/resolve-none-computed-style";
import { HTML_ATTRIBUTE_VISUAL_PARENT } from ".";
import { getOtherOptionsFromWindow } from "../../utils";
var CHILDREN_SELECTOR_PREFIX = "children:";
/**
 * Find element where the content blocker should be placed (e. g. a
 * content blocker should be inside a link).
 */

function findVisualParentForContentBlocker(node) {
  if (!node.parentElement) {
    return [node, "none"];
  } // Find visual parent attribute


  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      setVisualParentIfClassOfParent = _getOtherOptionsFromW.setVisualParentIfClassOfParent;

  var forceVisualParent = ["a"].indexOf(node.parentElement.tagName.toLowerCase()) > -1; // for links always use the parent

  var useVisualParent = forceVisualParent;

  if (node.hasAttribute(HTML_ATTRIBUTE_VISUAL_PARENT)) {
    useVisualParent = node.getAttribute(HTML_ATTRIBUTE_VISUAL_PARENT);
  } else {
    var className = node.parentElement.className;

    for (var def in setVisualParentIfClassOfParent) {
      if (className.indexOf(def) > -1) {
        useVisualParent = setVisualParentIfClassOfParent[def];
        break;
      }
    }
  }

  if (useVisualParent) {
    if (useVisualParent === true || useVisualParent === "true") {
      // Usual behavior, just take the parent
      return [node.parentElement, "parent"];
    } else if (!isNaN(+useVisualParent)) {
      // The attribute is a number, let's go up x parents
      var parent = node;

      for (var i = 0; i < +useVisualParent; i++) {
        if (!parent.parentElement) {
          return [parent, "parentZ"]; // Fallback to last found parent
        }

        parent = parent.parentElement;
      }

      return [parent, "parentZ"];
    } else if (typeof useVisualParent === "string") {
      if (useVisualParent.startsWith(CHILDREN_SELECTOR_PREFIX)) {
        return [node.querySelector(useVisualParent.substr(CHILDREN_SELECTOR_PREFIX.length)), "childrenSelector"];
      } else {
        // The attribute is a query selector
        for (var elem = node; elem; elem = elem.parentElement) {
          if (matchingSelector(elem, useVisualParent)) {
            return [elem, "parentSelector"];
          }
        }
      }
    }
  }

  return [node, "none"];
}

export { findVisualParentForContentBlocker };