import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { getPreviousVisibleElement } from "../../utils";
import { HTML_ATTRIBUTE_BLOCKER_CONNECTED, HTML_ATTRIBUTE_BLOCKER_ID } from ".";

function isHtmlNodeContentBlocker(node) {
  return node.hasAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED);
}

function mapIgnoreInvisibleAndUsePreviousVisible(element) {
  return element.offsetParent ? element : getPreviousVisibleElement(element, isHtmlNodeContentBlocker);
}
/**
 * Find a duplicate before a given node to avoid duplicate content blockers for the same content.
 */


function findPreviousContentBlockerDuplicate(node, blockerId) {
  var _node$parentElement, _node$parentElement2, _node$parentElement2$, _secondParentPrevious;

  var previousElementSibling = node.previousElementSibling;
  var firstParentPreviousElementSibling = (_node$parentElement = node.parentElement) === null || _node$parentElement === void 0 ? void 0 : _node$parentElement.previousElementSibling;
  var secondParentPreviousElementSibling = (_node$parentElement2 = node.parentElement) === null || _node$parentElement2 === void 0 ? void 0 : (_node$parentElement2$ = _node$parentElement2.parentElement) === null || _node$parentElement2$ === void 0 ? void 0 : _node$parentElement2$.previousElementSibling;
  var possibleDuplicates = [getPreviousVisibleElement(node, isHtmlNodeContentBlocker), previousElementSibling, previousElementSibling === null || previousElementSibling === void 0 ? void 0 : previousElementSibling.lastElementChild, // 1st parent up
  firstParentPreviousElementSibling, firstParentPreviousElementSibling === null || firstParentPreviousElementSibling === void 0 ? void 0 : firstParentPreviousElementSibling.lastElementChild, // 2nd parent up
  secondParentPreviousElementSibling, secondParentPreviousElementSibling === null || secondParentPreviousElementSibling === void 0 ? void 0 : secondParentPreviousElementSibling.lastElementChild, secondParentPreviousElementSibling === null || secondParentPreviousElementSibling === void 0 ? void 0 : (_secondParentPrevious = secondParentPreviousElementSibling.lastElementChild) === null || _secondParentPrevious === void 0 ? void 0 : _secondParentPrevious.lastElementChild].filter(Boolean) // Get previous visible element if the last child element is not visible
  .map(mapIgnoreInvisibleAndUsePreviousVisible).filter(Boolean);

  var _iterator = _createForOfIteratorHelper(possibleDuplicates),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var possibleDuplicate = _step.value;

      if (+possibleDuplicate.getAttribute(HTML_ATTRIBUTE_BLOCKER_ID) === blockerId && possibleDuplicate.hasAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED)) {
        return possibleDuplicate;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return false;
}

export { findPreviousContentBlockerDuplicate };