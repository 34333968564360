import { OPT_IN_ALL_EVENT, BANNER_PRE_DECISION_SHOW_EVENT } from "../../types";
import { manipulateContentBlockerDOM } from ".";
import { applyContentBlockRatioCssPlainCss } from "..";
/**
 * Listen to opt-in of all cookies so we can enable the blocked content or
 * create an visual content blocker. It also listens if no / or invalid consent is given.
 */

function listenOptInForContentBlocker() {
  var manipulateInterval;
  document.addEventListener(OPT_IN_ALL_EVENT, function (_ref) {
    var cookies = _ref.detail.cookies;
    manipulateContentBlockerDOM(cookies);
    clearInterval(manipulateInterval);
    manipulateInterval = setInterval(function () {
      manipulateContentBlockerDOM(cookies);
    }, 1000);
  });
  document.addEventListener(BANNER_PRE_DECISION_SHOW_EVENT, function () {
    manipulateContentBlockerDOM([]);
  });
  applyContentBlockRatioCssPlainCss();
}

export { listenOptInForContentBlocker };