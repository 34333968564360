/* istanbul ignore file: we do not need to care about the entry point file as errors are detected through integration tests (E2E) */

/**
 * Entrypoint for plain website. This file is loaded on all pages.
 */
import { delegateClickThroughUnblockedContent, listenOptInForContentBlocker, listenOptInJqueryFnForContentBlocker, memorizeJQueryEvent, overwriteElementorVideoApi } from "./others";
import { listenDecideToUnblockTcf } from "./tcf";
import { ready } from "./utils";
delegateClickThroughUnblockedContent();
listenOptInForContentBlocker();
listenOptInJqueryFnForContentBlocker(["fitVids", "mediaelementplayer"]);
overwriteElementorVideoApi();

if (process.env.IS_TCF === "1") {
  listenDecideToUnblockTcf();
}

ready(function () {
  memorizeJQueryEvent(document, document, "tve-dash.load"); // Thrive Leads
}, "interactive");