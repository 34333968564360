function matchingSelector(element, selector) {
  var matchingElements = element.parentElement.querySelectorAll(selector);

  for (var matchIdx in matchingElements) {
    if (matchingElements[matchIdx] === element) {
      return true;
    }
  }

  return false;
}

export { matchingSelector };