import _extends from "@babel/runtime/helpers/extends";
import { Fragment } from "react";
import { useBlocker } from "../../contexts";
import { BlockerFooterInlineStyles, BlockerFooterLink } from ".";
import { getOtherOptionsFromWindow, jsxJoin } from "../../utils";
import { BannerTooltip } from "../banner";
import { useBannerLegalLinks } from "../../hooks";

var BlockerFooter = function BlockerFooter() {
  var blocker = useBlocker();
  var legal = blocker.legal,
      _blocker$footerDesign = blocker.footerDesign,
      poweredByLink = _blocker$footerDesign.poweredByLink,
      linkTarget = _blocker$footerDesign.linkTarget,
      poweredLink = blocker.poweredLink;
  var targetAttributes = linkTarget === "_blank" ? {
    target: "_blank",
    rel: "noopener"
  } : {};

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      isPro = _getOtherOptionsFromW.isPro,
      affiliate = _getOtherOptionsFromW.affiliate;

  var _useBannerLegalLinks = useBannerLegalLinks(legal),
      linkPrivacyPolicy = _useBannerLegalLinks.linkPrivacyPolicy,
      linkImprint = _useBannerLegalLinks.linkImprint;

  var links = jsxJoin([linkPrivacyPolicy && h(BlockerFooterLink, _extends({
    href: linkPrivacyPolicy.url
  }, targetAttributes, {
    key: "privacyPolicy"
  }), linkPrivacyPolicy.label), linkImprint && h(BlockerFooterLink, _extends({
    href: linkImprint.url
  }, targetAttributes, {
    key: "imprint"
  }), linkImprint.label)], h(Fragment, null, " \u2022 "));
  return h("div", BlockerFooterInlineStyles.footerContainer(blocker), h("div", BlockerFooterInlineStyles.footer(blocker), links, !!poweredLink && (poweredByLink || !isPro) && h(Fragment, null, links !== null && h("br", null), h(BlockerFooterLink, {
    href: affiliate ? affiliate.link : poweredLink.href,
    target: poweredLink.target
  }, h("span", {
    dangerouslySetInnerHTML: {
      __html: poweredLink.innerHTML
    }
  }), affiliate && h(BannerTooltip, {
    title: affiliate.description
  }, " ", affiliate.labelBehind)), " ")));
};

export { BlockerFooter };