import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { resolveNoneComputedStyle } from "@devowl-wp/resolve-none-computed-style";
import { HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN } from ".";
import { KNOWN_RATIO_CLASSES, RESET_PARENT_HTML_ATTRIBUTE, RESET_PARENT_HTML_ATTRIBUTE_VALUE } from "..";
var RESET_STYLE = {
  height: "auto",
  padding: 0
};
var MEMORIZE_OLD_STYLE = "consent-cb-memo-style";
/**
 * Example: Vimeo responsive video.
 *
 * ```html
 * <div style="padding:56.25% 0 0 0;position:relative;">
 *  <iframe style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
 * </div>
 * ```
 */

function isPotentialContentBlockerWithinResponsiveContainer(node) {
  var _node$style;

  var parentElement = node.parentElement;

  if (!parentElement) {
    return false;
  }

  var position = ((_node$style = node.style) === null || _node$style === void 0 ? void 0 : _node$style.position) || "initial";
  var _parentElement$style = parentElement.style,
      parentPosition = _parentElement$style.position,
      parentPadding = _parentElement$style.padding;
  return position === "absolute" && parentPosition === "relative" && parentPadding.indexOf("%") > -1;
}
/**
 * Modify parent element with some styles, e. g. Elementor puts some padding into it.
 * We need to enforce the style via inline-style because some themes use `!important` in their
 * classes, too.
 *
 * @param state If `true`, the reset-style will be forced and vice-versa
 */


function probablyResetParentContainerForContentBlocker(contentBlocker, state) {
  var _parentElement$parent;

  var parentElement = contentBlocker.parentElement;
  var iterateParents = [parentElement, parentElement === null || parentElement === void 0 ? void 0 : parentElement.parentElement, parentElement === null || parentElement === void 0 ? void 0 : (_parentElement$parent = parentElement.parentElement) === null || _parentElement$parent === void 0 ? void 0 : _parentElement$parent.parentElement].filter(Boolean); // Go max three levels upwards

  var _iterator = _createForOfIteratorHelper(iterateParents),
      _step;

  try {
    var _loop = function _loop() {
      var parent = _step.value;
      var foundRatioClass = KNOWN_RATIO_CLASSES.filter(function (c) {
        return parent.className.indexOf(c) > -1;
      }).length > 0;
      var isFirstItemWithinResponsiveContainer = parent === parentElement && isPotentialContentBlockerWithinResponsiveContainer(contentBlocker);

      if (state && (isFirstItemWithinResponsiveContainer || foundRatioClass || [0, "0%", "0px"].indexOf(resolveNoneComputedStyle(parent, "height")) > -1)) {
        // Check the `display:none!important` style
        var previouslyStrictHidden = parent.hasAttribute(HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN);
        var style = parent.getAttribute("style") || "";
        parent.removeAttribute(HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN);

        if (!previouslyStrictHidden) {
          style = style.replace(/display:\s*none\s*!important;/, "");
        } // Apply our new styles with memorization


        parent.setAttribute(RESET_PARENT_HTML_ATTRIBUTE, RESET_PARENT_HTML_ATTRIBUTE_VALUE);
        parent.setAttribute(MEMORIZE_OLD_STYLE, style);

        for (var property in RESET_STYLE) {
          parent.style.setProperty(property, RESET_STYLE[property], "important");
        } // Special use case: Positioning


        if (window.getComputedStyle(parent).position === "absolute") {
          parent.style.setProperty("position", "static", "important");
        }
      } else if (!state && parent.hasAttribute(RESET_PARENT_HTML_ATTRIBUTE)) {
        parent.setAttribute("style", parent.getAttribute(MEMORIZE_OLD_STYLE) || "");
        parent.removeAttribute(MEMORIZE_OLD_STYLE);
        parent.removeAttribute(RESET_PARENT_HTML_ATTRIBUTE);
      }
    };

    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      _loop();
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}

export { isPotentialContentBlockerWithinResponsiveContainer, probablyResetParentContainerForContentBlocker };