import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { HTML_ATTRIBUTE_INLINE_STYLE, HTML_TAG_CONSENT_SCRIPT, putStyleInlineToDom, decideToUnblockContentBlocker } from ".";
/**
 * Get all blocked rules within a inline CSS style and check, if they can be enabled by the consent cookies.
 */

function applyContentBlockerStyleRule(cookies) {
  var nodes = Array.prototype.slice.call(document.querySelectorAll("[".concat(HTML_ATTRIBUTE_INLINE_STYLE, "]"))); // Iterate all `style`'s

  var _iterator = _createForOfIteratorHelper(nodes),
      _step;

  try {
    var _loop = function _loop() {
      var node = _step.value;
      // Get the inline style (can be inside attribute when it should be placed to DOM the first time)
      var isFirstTransition = node.tagName.toLowerCase() === HTML_TAG_CONSENT_SCRIPT;
      var innerHTML = isFirstTransition ? node.getAttribute(HTML_ATTRIBUTE_INLINE_STYLE) : node.innerHTML; // Apply our regex to replace accepted URLs (https://regex101.com/r/9FJWnR/1)

      var noConsentCount = 0;
      var newInnerHTML = innerHTML.replace(/(url\s*\(["'\s]*)([^"]+dummy\.(?:png|css))\?consent-required=([0-9,]+)&consent-by=(\w+)&consent-id=(\d+)&consent-original-url=([^-]+)-/gm, function (m, urlPrefix, maskedUrl, requiredString, by, blockerId, base64EncodedUrl) {
        var _decideToUnblockConte = decideToUnblockContentBlocker(cookies, by, requiredString, +blockerId),
            consent = _decideToUnblockConte.consent;

        if (!consent) {
          noConsentCount++;
        }

        return consent ? "".concat(urlPrefix).concat(atob(base64EncodedUrl)) : m;
      }); // Put pack to DOM

      if (isFirstTransition) {
        node.setAttribute(HTML_ATTRIBUTE_INLINE_STYLE, newInnerHTML);
        putStyleInlineToDom(node);
      } else {
        if (node.innerHTML !== newInnerHTML) {
          node.innerHTML = newInnerHTML;
        }

        if (noConsentCount === 0) {
          node.removeAttribute(HTML_ATTRIBUTE_INLINE_STYLE);
        }
      }
    };

    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      _loop();
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}

export { applyContentBlockerStyleRule };