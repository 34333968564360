import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { OPT_IN_CONTENT_BLOCKER } from "../../types"; // import $ from "jquery"; // not always available

import { HTML_ATTRIBUTE_COOKIE_IDS } from "..";
import { ready } from "../../utils";
var $ = window.jQuery;
var ALREADY_OVERWRITTEN_PROPERTY = "listenOptInJqueryFnForContentBlockerNow";
/**
 * Modify a jQuery function to wait for consent if a content blocker is within the
 * given selector.
 */

function listenOptInJqueryFnForContentBlocker(fns) {
  listenOptInJqueryFnForContentBlockerNow(fns); // jQuery fn cannot be ready yet, let's wait for DOM ready

  ready(function () {
    listenOptInJqueryFnForContentBlockerNow(fns);
  });
}

function listenOptInJqueryFnForContentBlockerNow(fns) {
  if (!($ !== null && $ !== void 0 && $.fn)) {
    return;
  }

  var jQueryFns = $.fn;

  var _iterator = _createForOfIteratorHelper(fns),
      _step;

  try {
    var _loop = function _loop() {
      var fn = _step.value;
      var originalFunction = jQueryFns[fn];

      if (!originalFunction) {
        return "continue";
      } // Already overwritten?


      var alreadyOverwritten = jQueryFns[ALREADY_OVERWRITTEN_PROPERTY] = jQueryFns[ALREADY_OVERWRITTEN_PROPERTY] || [];

      if (alreadyOverwritten.indexOf(fn) > -1) {
        return "continue";
      }

      alreadyOverwritten.push(fn);

      jQueryFns[fn] = function () {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        return this.each(function () {
          var _this = this;

          var callOriginal = function callOriginal() {
            return originalFunction.apply($(_this), args);
          };

          var nodes = Array.prototype.slice.call(this.querySelectorAll("[".concat(HTML_ATTRIBUTE_COOKIE_IDS, "]"))); // Also respect own element

          if (this.getAttribute(HTML_ATTRIBUTE_COOKIE_IDS)) {
            nodes.push(this);
          } // Call original function immediate if no content blocker found


          if (!nodes.length) {
            callOriginal();
          } else {
            // Wait for all unblocked content
            Promise.all(nodes.map(function (node) {
              return new Promise(function (resolve) {
                return node.addEventListener(OPT_IN_CONTENT_BLOCKER, resolve);
              });
            })).then(function () {
              return callOriginal();
            });
          }
        });
      };
    };

    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _ret = _loop();

      if (_ret === "continue") continue;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}

export { listenOptInJqueryFnForContentBlocker };