import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { DECIDE_TO_UNBLOCK_CONTENT_BLOCKER_EVENT } from "../../types";
import { getOtherOptionsFromWindow } from "../../utils";
/**
 * Given by criteria, cookie IDs and content blocker ID decide if the consent is given.
 * This function currently only supports `criteria=cookies`. If you are using a custom
 * criteria, you need to resolve the boolean by an event.
 */

function decideToUnblockContentBlocker(cookies, by, requiredIds, blockerId) {
  var _allBlocker$filter;

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      allBlocker = _getOtherOptionsFromW.blocker;

  var blocker = (_allBlocker$filter = allBlocker.filter(function (_ref) {
    var id = _ref.id;
    return id === blockerId;
  })) === null || _allBlocker$filter === void 0 ? void 0 : _allBlocker$filter[0];
  var required = typeof requiredIds === "string" ? requiredIds.split(",").map(Number) : requiredIds; // Check if consent is given for required cookies

  var consent = by === "cookies" ? required.map(function (requiredId) {
    var _iterator = _createForOfIteratorHelper(cookies),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var id = _step.value.cookie.id;

        if (id === requiredId) {
          return true;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return false;
  }).indexOf(false) === -1 : true; // a third-party needs to calculate the consent via an event (e.g. TCF)

  var detail = {
    blocker: blocker,
    by: by,
    consent: consent,
    cookies: cookies,
    required: required
  }; // Allow third parties to decide individually.

  document.dispatchEvent(new CustomEvent(DECIDE_TO_UNBLOCK_CONTENT_BLOCKER_EVENT, {
    detail: detail
  }));
  return {
    consent: detail.consent,
    blocker: detail.blocker
  };
}

export { decideToUnblockContentBlocker };