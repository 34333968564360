import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
var RESET_PARENT_HTML_ATTRIBUTE = "consent-cb-reset-parent";
var RESET_PARENT_HTML_ATTRIBUTE_VALUE = "1";
var KNOWN_RATIO_CLASSES = [// Elementor
"-fit-aspect-ratio", // Gutenberg
"wp-block-embed__wrapper", // Cornerstone
"x-frame-inner"];
/**
 * In some cases it is necessary to reset some styles which are not resettable through
 * the `style` attribute. E.g. pseudo-classes like `::before` and `::after`.
 */

function applyContentBlockRatioCssPlainCss() {
  var element = document.createElement("style");
  element.style.type = "text/css";
  document.getElementsByTagName("head")[0].appendChild(element);
  var selectorResetParentAttribute = "".concat(RESET_PARENT_HTML_ATTRIBUTE, "=\"").concat(RESET_PARENT_HTML_ATTRIBUTE_VALUE, "\"");
  var selectorContentBlocker = ".rcb-content-blocker";
  var rules = [].concat(_toConsumableArray([// Thrive Architect
  ".thrv_wrapper[".concat(selectorResetParentAttribute, "]")].map(function (s) {
    return "".concat(s, "::before{display:none!important;}");
  })), _toConsumableArray([// JetElements for Elementor
  ".jet-video[".concat(selectorResetParentAttribute, "]>.jet-video__overlay"), // Divi Page Builder
  ".et_pb_video[".concat(selectorResetParentAttribute, "]>.et_pb_video_overlay"), // Ultimate Video (WP Bakery Page Builder)
  "".concat(selectorContentBlocker, "+.ultv-video"), // Ultimate Addons for Elementor
  "".concat(selectorContentBlocker, "+.elementor-widget-container"), // Astra Theme (Gutenberg Block)
  ".wp-block-embed__wrapper[".concat(selectorResetParentAttribute, "]>.ast-oembed-container")].map(function (s) {
    return "".concat(s, "{display:none!important;}");
  })), [// Other rules: If you add a similar one, please merge to an array!
  // Gutenberg
  ".wp-block-embed__wrapper[".concat(selectorResetParentAttribute, "]::before{padding-top:0!important;}"), // Thrive Architect
  ".tve_responsive_video_container[".concat(selectorResetParentAttribute, "]{padding-bottom:0!important;}")], _toConsumableArray([// JetElements for Elementor
  ".jet-video[".concat(selectorResetParentAttribute, "]")].map(function (s) {
    return "".concat(s, "{background:none!important;}");
  })), _toConsumableArray([// Thrive Architect
  ".tve_responsive_video_container[".concat(selectorResetParentAttribute, "]")].map(function (s) {
    return "".concat(s, " .rcb-content-blocker > div > div > div {border-radius:0!important;}");
  })));
  element.innerHTML = rules.join("");
}

export { applyContentBlockRatioCssPlainCss, RESET_PARENT_HTML_ATTRIBUTE, RESET_PARENT_HTML_ATTRIBUTE_VALUE, KNOWN_RATIO_CLASSES };