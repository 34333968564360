import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { render } from "react-dom";
import { WebsiteBlocker } from "../../components";
import { getSsrPoweredByLink } from "..";
import { getOtherOptionsFromWindow } from "../../utils";
import { probablyResetParentContainerForContentBlocker, isPotentialContentBlockerWithinResponsiveContainer, findVisualParentForContentBlocker, findPreviousContentBlockerDuplicate, HTML_ATTRIBUTE_BLOCKER_CONNECTED, HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_INLINE, HTML_ATTRIBUTE_INLINE_STYLE } from ".";
var connectedCounter = 0;
export var HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN = "consent-strict-hidden";
/**
 * Create visual content blocker for a given node.
 */

function createContentBlocker(_ref) {
  var _node$style;

  var node = _ref.node,
      blocker = _ref.blocker;

  // Short circuit: We do not know a blocker for this; do nothing
  if (!blocker) {
    return;
  }

  var parentElement = node.parentElement;
  var forceHidden = blocker.forceHidden,
      visual = blocker.visual,
      blockerId = blocker.id;
  var position = ((_node$style = node.style) === null || _node$style === void 0 ? void 0 : _node$style.position) || "initial"; // Visual content blockers are only allowed for "real" content

  var isPositioned = ["fixed", "absolute", "sticky"].indexOf(position) > -1;
  var isNotInContent = [document.body, document.head, document.querySelector("html")].indexOf(parentElement) > -1;
  var isAlreadyConnected = node.getAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED);

  var _findVisualParentForC = findVisualParentForContentBlocker(node),
      _findVisualParentForC2 = _slicedToArray(_findVisualParentForC, 2),
      createBefore = _findVisualParentForC2[0],
      visualParentMode = _findVisualParentForC2[1];

  var isInlineScriptOrStyle = createBefore.hasAttribute(HTML_ATTRIBUTE_INLINE) || createBefore.hasAttribute(HTML_ATTRIBUTE_INLINE_STYLE); // Always hide elements (again) if no visual blocker is possible (get) and memorize
  // the "!important" state so we can correctly reset it in `probablyResetParentContainerForContentBlocker`

  var fnStrictHide = function fnStrictHide() {
    if (["script", "link"].indexOf(node === null || node === void 0 ? void 0 : node.tagName.toLowerCase()) === -1 && visualParentMode !== "childrenSelector") {
      var style = node.style;

      if (style.getPropertyValue("display") === "none" && style.getPropertyPriority("display") === "important") {
        node.setAttribute(HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN, "1");
      } else {
        style.setProperty("display", "none", "important");
      }
    }
  };

  if (isNotInContent || isPositioned && !isPotentialContentBlockerWithinResponsiveContainer(node) && !forceHidden || !visual || isAlreadyConnected || !isInlineScriptOrStyle && !createBefore.offsetParent && !forceHidden) {
    fnStrictHide();
    return;
  }

  var container = document.createElement("div"); // Avoid duplicate visual blockers and connect them

  var foundDuplicate = findPreviousContentBlockerDuplicate(createBefore, blockerId);

  if (foundDuplicate) {
    node.setAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED, foundDuplicate.getAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED));
    fnStrictHide();
    return;
  } // Connect blocker with blocked node


  container.setAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED, connectedCounter.toString());
  container.setAttribute(HTML_ATTRIBUTE_BLOCKER_ID, blockerId.toString());
  container.className = "rcb-content-blocker";
  container.style.setProperty("max-height", "initial");
  node.setAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED, connectedCounter.toString());
  createBefore.parentNode.insertBefore(container, createBefore); // Connect a children selector with the content blocker above

  if (visualParentMode === "childrenSelector") {
    createBefore.setAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED, connectedCounter.toString());
  } // Do not translate this content blocker with an output buffer plugin like TranslatePress or Weglot


  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      multilingualSkipHTMLForTag = _getOtherOptionsFromW.multilingualSkipHTMLForTag;

  if (multilingualSkipHTMLForTag) {
    container.setAttribute(multilingualSkipHTMLForTag, "1");
  } // Hide our element and render content blocker


  (visualParentMode === "childrenSelector" ? createBefore : node).style.setProperty("display", "none", "important");
  render(h(WebsiteBlocker, {
    poweredLink: getSsrPoweredByLink(),
    blocker: blocker,
    connectedCounter: connectedCounter
  }), container);
  connectedCounter++;
  probablyResetParentContainerForContentBlocker(createBefore, true);
}

export { createContentBlocker };