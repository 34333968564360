import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { OPT_IN_CONTENT_BLOCKER_ALL } from "../../types";
import { isCurrentlyInTransaction } from "..";
import { MEMORIZE_PROPERTY } from ".";
var OVERWRITE_PROPERTY = "rcbJQueryEventListener";
/**
 * Overwrite `jQuery(selector).on` and `jQuery.event.add` for special events
 * like `elementor/frontend/init`.
 */

function applyJQueryEvent(doc, element, eventName) {
  var _Object$assign;

  var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
    onBeforeExecute: undefined
  },
      onBeforeExecute = _ref.onBeforeExecute;

  var overwriteProp = "".concat(OVERWRITE_PROPERTY, "_").concat(eventName);
  var memorizeProp = "".concat(MEMORIZE_PROPERTY, "_").concat(eventName);

  var _ref2 = doc.defaultView || doc.parentWindow,
      jQuery = _ref2.jQuery;

  if (!jQuery) {
    return;
  }

  var event = jQuery.event,
      Event = jQuery.Event;

  if (!event || !Event || event[overwriteProp]) {
    return;
  }

  var _add = event.add;
  Object.assign(event, (_Object$assign = {}, _defineProperty(_Object$assign, overwriteProp, true), _defineProperty(_Object$assign, "add", function add() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    // https://git.io/JsXSb
    var elem = args[0],
        types = args[1],
        handler = args[2],
        data = args[3],
        selector = args[4];
    var useTypes = Array.isArray(types) ? types : typeof types === "string" ? types.split(" ") : types;
    var memorizeExecutionPromise = event[memorizeProp];
    var inTransaction = isCurrentlyInTransaction(); // Redirect to own thread to avoid variable order lacks (e. g. Uncode Gmaps Integration, Contact Form 7)

    var executeHandle = function executeHandle() {
      return setTimeout(function () {
        onBeforeExecute === null || onBeforeExecute === void 0 ? void 0 : onBeforeExecute(inTransaction);
        handler === null || handler === void 0 ? void 0 : handler(new Event());
      }, 0);
    };

    if (types && elem === element) {
      var _iterator = _createForOfIteratorHelper(useTypes),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var type = _step.value;
          var isRequestedEventName = type === eventName;

          if (isRequestedEventName && inTransaction) {
            (function () {
              var executed = false;
              document.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, function () {
                // IE does not support the `once` option
                if (executed) {
                  return;
                }

                executed = true;

                if (memorizeExecutionPromise) {
                  memorizeExecutionPromise.then(executeHandle);
                } else {
                  executeHandle();
                }
              });
            })();
          } else if (isRequestedEventName && memorizeExecutionPromise) {
            memorizeExecutionPromise.then(executeHandle);
          } else {
            _add.apply(this, [elem, type, handler, data, selector]);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    } else {
      _add.apply(this, args);
    }
  }), _Object$assign));
}

export { applyJQueryEvent };